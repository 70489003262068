<template>
  <footer v-if="isShow">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="logo-social">
          <router-link
            id="homeMenuFooter"
            :to="{ name: 'home' }" >
            <img
              class="logo"
              src="@/assets/icons/logo-white.svg"
              alt="Logo truecoffee go">
          </router-link>

          <div class="social">
            <a
              v-for="(social, i) in socials"
              :key="i"
              :id="social.id"
              :href="social.link"
              target="_blank" >
              <div class="box-link-social">
                <font-awesome-icon
                  :icon="['fab', social.icon]"
                  class="icon" />
                <!-- <img :src="social.icon" alt="Line icon"> -->
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 align-items-center">
        <div class="nav-app">
          <nav class="row">
            <router-link
              v-for="(menu, i) in menus"
              :key="i"
              :id="menu.id_footer"
              :to="menu.router_name"
              class="col-6 col-md-auto" >
              {{ menu.title }}
            </router-link>
          </nav>

          <!-- <div class="app">
            <a
              v-for="(app, i) in apps"
              :key="i"
              :id="i === 0 ? 'downloadAppiOSFooter' : 'downloadAppAndroidFooter'"
              :data-name="app.data_name"
              :href="app.link"
              target="_blank" >
              <img
                :src="require(`@/assets/images/${app.image_name}`)"
                :alt="app.alt">
            </a>
          </div> -->
        </div>
      </div>

      <div class="col-12">
        <p class="copyright">© TrueCoffee</p>
      </div>
    </div>
  </footer>
</template>

<script>
import menus from '@/resources/menus.json'
import apps from '@/resources/apps.json'

export default {
  data () {
    return {
      isShow: true,
      socials: [
        {
          icon: 'line',
          link: 'http://line.me/ti/p/%40truecoffeego',
          id: 'lineSocialFooter'
        },
        {
          icon: 'facebook-f',
          link: 'https://www.facebook.com/truecoffeego',
          id: 'facebookSocialFooter'
        },
        // {
        //   icon: 'twitter',
        //   link: ''
        // },
        {
          icon: 'instagram',
          link: 'https://www.instagram.com/truecoffeego_',
          id: 'instagramSocialFooter'
        }
      ],
      menus: menus,
      apps: apps
    }
  },

  methods: {
    checkShow () {
      const routerName = this.$route.name

      if (routerName === 'landing') {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  },

  watch: {
    '$route' (route) {
      this.checkShow()

      if (route.name === 'ladding') {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  },

  created () {
    this.checkShow()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

footer {
  position: relative;
  z-index: 9;
  padding: 20px 0;
  padding-left: 85px;
  padding-right: 85px;
  background-color: #222;

  @media screen and (min-width: 768px) {
    padding: 12px 60px;
  }

  .logo-social {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 768px) {
      align-items: flex-start;
    }

    .logo {
      margin: 10px 0;
      height: 50px;

      @media screen and (min-width: 768px) {
        margin: 2px 0;
        height: 36px;
      }
    }

    .social {
      display: flex;
      margin: 20px 0;

      @media screen and (min-width: 768px) {
        margin: 12px 0;
      }

      a {
        margin: 0 6px;

        .box-link-social {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 28px;
          border-radius: 14px;
          // border: 1px solid white;
          // transition: 0.3s $ease;

          @media screen and (min-width: 768px) {
            width: 20px;
            height: 20px;
            // border: 1px solid white;
          }

          .icon {
            font-size: 16px;
            color: white;
            transition: 0.3s $ease;
          }
        }

        .box-link-social:hover {
          // filter: brightness(0.5) sepia(1) hue-rotate(0deg) saturate(100);

          .icon {
            color: $color-main;
          }
        }
      }

      @media screen and (min-width: 768px) {
        a:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .nav-app {
    nav {
      @media screen and (min-width: 768px) {
        justify-content: flex-end;
        margin-right: 0;
      }

      a {
        padding: 10px 0;
        font-family: 'DB Ozone X Bd';
        text-align: center;
        letter-spacing: 1px;
        font-size: 14px;
        color: white;
        transition: 0.3s $ease;

        @media screen and (min-width: 768px) {
          padding-top: 3px;
          padding-bottom: 3px;
          padding-left: 22px;
        }
      }

      a:active {
        color: white;
      }

      a:hover {
        color: $color-main;
      }

      @media screen and (min-width: 768px) {
        a:first-child {
          padding-left: 0;
        }
      }
    }

    .app {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: flex-end;
        padding: 4px 0;
      }

      a {
        display: flex;
        justify-content: center;
        margin: 5px 0;

        @media screen and (min-width: 768px) {
          margin: 5px 6px;
        }

        img {
          width: 178px;
          height: 100%;
          border-radius: 8px;

          @media screen and (min-width: 768px) {
            width: auto;
            height: 38px;
            border-radius: 6px;
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
        a:last-child {
          margin-right: 0;
        }
      }
  }

  .copyright {
    font-size: 18px;
    text-align: center;
    color: white;

    @media screen and (min-width: 768px) {
      text-align: left;
      font-size: 12px;
    }
  }
}
</style>
